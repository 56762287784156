import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {initializeSounds, stopAllSounds} from './components/store/soundSlice'; // Import stopAllSounds action
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './components/login/Login';
import ResetPassword from './components/reset-password/ResetPassword';
import {AuthProvider} from './components/login/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/navbar/Navbar';
import SettingsPage from './components/settings/SettingsPage';
import Footer from './components/footer/Footer';
import MainComponent from './components/MainSoundComponent';
import VerifyEmailPage from "./components/login/VerifyEmailPage";
import About from "./components/about-us/AboutUs";
import Newsletter from './components/newsletter/Newsletter';
import Pomodoro from "./components/pomodoro/Pomodoro";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const sessionKey = 'appSessionActive';
        if (!sessionStorage.getItem(sessionKey)) {
            dispatch(stopAllSounds());
            sessionStorage.setItem(sessionKey, 'true');
        } else {
            dispatch(initializeSounds());
        }
    }, [dispatch]);

    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Navbar/>
                    <div className="main-content">
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/reset-password" element={<ResetPassword/>}/>
                            <Route path="/verify-email" element={<VerifyEmailPage/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/newsletter" element={<Newsletter/>}/>
                            <Route path="/pomodoro" element={<Pomodoro/>}/>
                            <Route path="/" element={<MainComponent/>}/>
                            <Route
                                path="/settings"
                                element={
                                    <ProtectedRoute>
                                        <SettingsPage/>
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
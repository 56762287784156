import {createSlice} from '@reduxjs/toolkit';
import {Howl} from 'howler';

const soundManager = {
    audios: {},
    play(soundName, soundFile, volume = 1.0) {
        if (!this.audios[soundName]) {
            this.audios[soundName] = new Howl({
                src: [soundFile],
                loop: true,
                volume: volume,
                preload: true,
            });
        }
        this.audios[soundName].play();
    },
    stop(soundName) {
        if (this.audios[soundName]) {
            this.audios[soundName].stop();
            delete this.audios[soundName];
        }
    },
    setVolume(soundName, volume) {
        if (this.audios[soundName]) {
            this.audios[soundName].volume(volume);
        }
    },
    pauseAll() {
        Object.values(this.audios).forEach((audio) => audio.pause());
    },
    playAll() {
        Object.values(this.audios).forEach((audio) => audio.play());
    },
    stopAll() {
        Object.values(this.audios).forEach((audio) => audio.stop());
        this.audios = {};
    },
};

const initialState = {
    playingAudios: {}, // { soundName: { soundFile: string, isPlaying: boolean } }
    selectedSounds: [],
    volumeLevels: {},
    isPaused: false,
    currentSession: null,
};

const soundSlice = createSlice({
    name: 'sounds',
    initialState,
    reducers: {
        initializeSounds: (state) => {
            Object.keys(state.playingAudios).forEach((soundName) => {
                const {soundFile, isPlaying} = state.playingAudios[soundName];
                soundManager.play(soundName, soundFile, state.volumeLevels[soundName] || 1.0);
                if (!isPlaying) {
                    soundManager.audios[soundName].pause();
                }
            });
        },
        playSound: (state, action) => {
            const {soundName, soundFile} = action.payload;
            console.log('Playing sound:', soundName);
            if (!state.playingAudios[soundName]) {
                state.playingAudios[soundName] = {soundFile, isPlaying: true};
                soundManager.play(soundName, soundFile);
                state.selectedSounds.push(soundName);
                state.volumeLevels[soundName] = 1.0;
            }
        },
        stopSound: (state, action) => {
            const soundName = action.payload;
            if (state.playingAudios[soundName]) {
                soundManager.stop(soundName);
                delete state.playingAudios[soundName];
                state.selectedSounds = state.selectedSounds.filter((name) => name !== soundName);
                delete state.volumeLevels[soundName];
            }
        },
        changeVolume: (state, action) => {
            const {soundName, volume} = action.payload;
            if (soundName === 'all') {
                state.globalVolume = volume;  // Update global volume
                Object.keys(state.playingAudios).forEach((name) => {
                    soundManager.setVolume(name, volume);
                    state.volumeLevels[name] = volume;
                });
            } else if (state.playingAudios[soundName]) {
                soundManager.setVolume(soundName, volume);
                state.volumeLevels[soundName] = volume;
            }
        },
        stopAllSounds: (state) => {
            soundManager.stopAll();
            state.playingAudios = {};
            state.selectedSounds = [];
            state.volumeLevels = {};
            state.isPaused = false;
        },
        togglePause: (state) => {
            state.isPaused = !state.isPaused;
            state.isPaused ? soundManager.pauseAll() : soundManager.playAll();
        },
        setCurrentSession: (state, action) => {
            state.currentSession = action.payload;
        },
    },
});

export const {
    initializeSounds,
    playSound,
    stopSound,
    changeVolume,
    stopAllSounds,
    togglePause,
    setCurrentSession,
} = soundSlice.actions;
export default soundSlice.reducer;
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { togglePause, changeVolume } from '../store/soundSlice';
import { FaPlay, FaPause, FaVolumeUp } from 'react-icons/fa';
import './GlobalControls.css';

const GlobalControls = () => {
    const dispatch = useDispatch();
    const isPaused = useSelector((state) => state.sounds.isPaused);
    const playingAudios = useSelector((state) => Object.keys(state.sounds.playingAudios).length > 0);
    const globalVolume = useSelector((state) => state.sounds.globalVolume || 1);

    const handlePauseClick = () => {
        dispatch(togglePause());
    };

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        dispatch(changeVolume({ soundName: 'all', volume: newVolume }));
    };

    return (
        <section className="global-controls" aria-live="assertive">
            <button
                className={`global-control-button ${isPaused ? 'play' : 'pause'}`}
                aria-label={isPaused ? 'Play all sounds' : 'Pause all sounds'}
                onClick={handlePauseClick}
                disabled={!playingAudios}
            >
                {isPaused ? <FaPlay /> : <FaPause />}
                <span>{isPaused ? 'Play' : 'Pause'}</span>
            </button>

            <div className="global-volume-control">
                <FaVolumeUp className="volume-icon" />
                <input
                    type="range"
                    id="global-volume"
                    min="0"
                    max="1"
                    step="0.01"
                    value={globalVolume}
                    onChange={handleVolumeChange}
                    aria-label="Adjust global volume"
                    disabled={!playingAudios}
                />
                <span className="global-volume-value" aria-live="polite">
                    {Math.round(globalVolume * 100)}%
                </span>
            </div>
        </section>
    );
};

export default GlobalControls;
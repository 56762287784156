import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
      <footer className="footer">
          <ul className="footer-links">
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/newsletter">Newsletter</Link></li>
          </ul>
      </footer>
  );
};

export default Footer;
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../login/AuthContext';
import './Navbar.css';
import { ReactComponent as LogoutIcon } from '../assets/logout.svg';
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import { ReactComponent as SoundIcon } from '../assets/sound.svg';
import { ReactComponent as GuestIcon } from '../assets/guest.svg';
import { ReactComponent as BrainIcon} from  "../assets/brain.svg";
import { useDispatch } from 'react-redux';
import { stopAllSounds } from '../store/soundSlice';

const Navbar = () => {
    const { signOutUser, currentUser } = useContext(AuthContext);
    const [profilePicture, setProfilePicture] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [showText, setShowText] = useState(window.innerWidth >= 1024);
    const menuRef = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(stopAllSounds());
        signOutUser();
    };

    useEffect(() => {
        const handleResize = () => {
            setShowText(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setProfilePicture(currentUser?.photoURL || null);
    }, [currentUser]);

    const getUserInitials = () => {
        if (currentUser?.displayName) {
            const initials = currentUser.displayName.match(/\b\w/g) || [];
            return initials.join('').toUpperCase();
        }
        return null;
    };

    const toggleMenu = () => {
        setShowMenu((prev) => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isActive = (path) => location.pathname === path;

    return (
        <div className="navbar-container">
            <div className="navbar-vertical">
                {!currentUser ? (
                    <>
                        <Link to="/login" className="navbar-login-btn">
                            <div className="navbar-login-text">Login</div>
                        </Link>
                    </>
                ) : (
                    <div className="navbar-profile-section" onClick={toggleMenu} ref={menuRef}>
                        {profilePicture ? (
                            <img
                                src={profilePicture}
                                alt="Profile"
                                className="navbar-profile-picture"
                                onError={(e) => (e.target.src = '../assets/placeholder-image.png')}
                            />
                        ) : currentUser.isAnonymous ? (
                            <div className="navbar-guest-icon">
                                <GuestIcon className="navbar-menu-icon" />
                                {showText && <span className="navbar-menu-text">Guest</span>}
                            </div>
                        ) : (
                            <div className="navbar-initials-circle">{getUserInitials()}</div>
                        )}
                        {showMenu && (
                            <div className="navbar-dropdown-menu">
                                <div className="navbar-settings-logout">
                                    {!currentUser.isAnonymous && (
                                        <Link
                                            to="/settings"
                                            className={`navbar-menu-item ${isActive('/settings') ? 'active' : ''}`}
                                        >
                                            <SettingsIcon className="navbar-menu-icon" />
                                        </Link>
                                    )}
                                    <button onClick={handleLogout} className="navbar-menu-item">
                                        <LogoutIcon className="navbar-menu-icon" />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* Sound button section */}
                <div className="navbar-sound-section">
                    <Link to="/" className="navbar-sound-btn">
                        <SoundIcon className="navbar-menu-icon" />
                    </Link>
                </div>
                {/* Sound button section */}
                <div className="navbar-sound-section">
                    <Link to="/pomodoro" className="navbar-sound-btn">
                        <BrainIcon className="navbar-menu-icon" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
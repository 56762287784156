// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import {
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail
} from 'firebase/auth';
import { auth } from './firebaseConfig'; // Correctly import auth from firebaseConfig
import './loading.css'; // Import your loading CSS

// Create context
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Ensure auth is correctly initialized
        if (!auth) {
            console.error("Firebase auth is not initialized correctly.");
            return;
        }

        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    // Function to sign in with email and password
    const signInWithEmail = async (email, password) => {
        try {
            return await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error('Error signing in with email:', error);
            throw error;
        }
    };

    // Function to sign up with email and password
    const signUpWithEmail = async (email, password) => {
        try {
            return await createUserWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error('Error signing up with email:', error);
            throw error;
        }
    };

    // Function to sign out the user
    const signOutUser = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
        } catch (error) {
            console.error('Error signing out:', error);
            throw error;
        }
    };

    // Function to reset the user's password
    const resetPassword = async (email) => {
        try {
            return await sendPasswordResetEmail(auth, email);
        } catch (error) {
            console.error('Error resetting password:', error);
            throw error;
        }
    };

    // Display loading spinner while Firebase checks for the current user
    if (loading) {
        return (
            <div className="loading-container">
                <div className="dot-pulse"></div>
            </div>
        );
    }

    return (
        <AuthContext.Provider value={{ currentUser, signInWithEmail, signUpWithEmail, signOutUser, resetPassword, auth }}>
            {children}
        </AuthContext.Provider>
    );
};
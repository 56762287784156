import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import './Pomodoro.css';

const Pomodoro = () => {
    const [breakTime, setBreakTime] = useState(300);
    const [sessionTime, setSessionTime] = useState(1500);
    const [clockTime, setClockTime] = useState(1500);
    const [clockType, setClockType] = useState('session');
    const [clockRunning, setClockRunning] = useState(false);
    const [tooltip, setTooltip] = useState(null);
    const [pomodoroCount, setPomodoroCount] = useState(0); // New State for Pomodoros
    const tooltipRef = useRef(null);

    const breakOptions = [300, 600, 900];
    const sessionOptions = [1500, 1800, 2700, 3600];

    const inMinSec = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };

    const switchClock = useCallback(() => {
        if (clockType === 'session') {
            setClockType('break');
            setClockTime(breakTime);
            setPomodoroCount(prevCount => prevCount + 1); // Increment Pomodoro count
        } else {
            setClockType('session');
            setClockTime(sessionTime);
        }
    }, [clockType, breakTime, sessionTime]);

    useEffect(() => {
        let countdownID;
        if (clockRunning && clockTime > 0) {
            countdownID = setInterval(() => {
                setClockTime((prevTime) => prevTime - 1);
            }, 1000);
        } else if (clockTime === 0 && clockRunning) {
            switchClock();
        }
        return () => clearInterval(countdownID);
    }, [clockRunning, clockTime, switchClock]);

    const showTooltip = () => {
        setTooltip('Cannot change time while Pomodoro is running');
        setTimeout(() => setTooltip(null), 2000);
    };

    const handleTimeChange = (type, value) => {
        if (clockRunning) return showTooltip();

        if (type === 'break') {
            setBreakTime(value);
            setClockType('break');
            setClockTime(value);
        } else if (type === 'session') {
            setSessionTime(value);
            setClockType('session');
            setClockTime(value);
        }
    };

    const handleStartPause = () => setClockRunning(!clockRunning);
    const handleReset = () => {
        setClockRunning(false);
        setBreakTime(300);
        setSessionTime(1500);
        setClockTime(1500);
        setClockType('session');
        setPomodoroCount(0); // Reset Pomodoro count
    };
    const handleModeToggle = () => {
        setClockRunning(false);
        switchClock();
    };

    return (
        <div className="timer-container">
            <Helmet>
                <title>Pomodoro Timer - Focus in Style</title>
                <meta name="description" content="Boost your productivity with our elegant Pomodoro timer."/>
            </Helmet>

            <h1 className="heading">Lets get things done!</h1>
            <div className="pomodoro-count">
                <h2>Completed Pomodoros:</h2>
                <div className="pomodoro-icons">
                    {Array.from({length: pomodoroCount}).map((_, index) => (
                        <span key={index} className="pomodoro-icon">🍅</span>
                    ))}
                </div>
            </div>
            <div className="controls">
                <div className="controls-block">
                    <p className="control-label">Break</p>
                    <select
                        value={breakTime}
                        onChange={(e) => handleTimeChange('break', parseInt(e.target.value))}
                        disabled={clockRunning}
                        className="time-select"
                    >
                        {breakOptions.map((option) => (
                            <option key={option} value={option}>
                                {option / 60} minutes
                            </option>
                        ))}
                    </select>
                </div>

                <div className="controls-block">
                    <p className="control-label">Session</p>
                    <select
                        value={sessionTime}
                        onChange={(e) => handleTimeChange('session', parseInt(e.target.value))}
                        disabled={clockRunning}
                        className="time-select"
                    >
                        {sessionOptions.map((option) => (
                            <option key={option} value={option}>
                                {option / 60} minutes
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="timer">
                <div className="timer-circle">
                    <span className="timer-time">{inMinSec(clockTime)}</span>
                    <p className="timer-mode-label">{clockType === 'session' ? 'Focus' : 'Break'}</p>
                </div>
            </div>

            <div className="timer-buttons">
                <button onClick={handleStartPause} className="timer-button start-button">
                    {clockRunning ? 'Pause' : 'Start'}
                </button>
                <button onClick={handleReset} className="timer-button reset-button">Reset</button>
            </div>
            <div className="mode-toggle">
                <button onClick={handleModeToggle}>Switch
                    to {clockType === 'session' ? 'Break Mode' : 'Focus Mode'}</button>
            </div>
            {tooltip && (
                <div className="tooltip" ref={tooltipRef}>
                    {tooltip}
                </div>
            )}
        </div>
    );
};

export default Pomodoro;